export class Settings {
  // Global Settings
  public static APP_NAME = 'JUSTMART';
  public static APP_VERSION = '0.0.1';


  // (+) EndPoints
  public static endPoints = {
    files: '/files',
    users: '/users',
    affiliates: '/affiliates',
    adherents: '/adherents',
    credential: '/credential',
    addClient: '/usr_AddCliente',
    addClientSocialLogin: '/usr_AddCliente',
    changePaymentMethod: '/upd_AnularPago',
    cancelPurchase: '/upd_AnularPedido',
    confirmOrder: '/upd_ConfirmarPedido',
    confirmReception: '/upd_RecibiConforme',
    confirmRapipago: '/upd_ReportaPago2Etapas',
    getCategoriesAndSubcategories: '/get_CategoriasSubCategorias',
    getClientAddress: '/get_ClienteDireccion',
    getCountries: '/get_Paises',
    getCurrentOrder: '/get_PedidoActual',
    getCurrentProducts: '/get_ProductosHabituales',
    getDaysSchedules: '/get_DiaHorarioDisponible',
    getDistributionCenter: '/get_IdCentDist',
    getFreeDelivery: '/msj_EnvioGratis',
    getLastStatus: '/get_UltPedidoEstado',
    getLocalities: '/get_LocalidadesProvincia',
    getNeighborhoods: '/get_BarriosLocalidad',
    getOrdersHistory: '/get_PedidoHistorial',
    getOrderInProgress: '/get_PedidoEnCurso',
    getOrdersInProgress: '/get_PedidosEnCursoCliente',
    getProductsByCategory: '/get_ProductosXCategoriaYSub',
    getProvinces: '/get_ProvinciasPais',
    getSaleProducts: '/get_Informacion',
    getSchedulesByDay: '/get_HorarioDisponible',
    getSearchProducts: '/get_ProductosBusqueda',
    getVersion: '/get_AppVersion',
    getVoucher: '/msj_Voucher',
    login: '/usr_ValidaUsuario',
    recoverPassword: '/usr_RestaurarContraseña',
    reportPayment: '/upd_ReportaPago',
    //reportPayment: '/upd_ReportaPago_V202206',
    sendSuggestion: '/upd_PedidoCalificacion',
    updateClient: '/upd_Cliente',
    updateClientAddress: '/upd_ClienteDireccion',
    updateDelivery: '/upd_PedidoFechaHora',
    updateOrder: '/add_PedidoProducto',
    updatePassword: '/usr_RestaurarContraseña',
    updateDataOnSignal: '/Update_DataOnSignal'
  };

  public static storage = {
    addressLatitude: 'justMart.addressLatitude',
    addressLongitude: 'justMart.addressLongitude',
    address: 'justMart.address',
    addressGoogle: 'justMart.addressGoogle',
    addressInfo: 'justMart.addressInfo',
    appVersion: 'justMart.appVersion',
    cart: 'justMart.cart',
    cartQuantity: 'justMart.cartQuantity',
    checkout: 'justMart.checkout',
    currentOrder: 'justMart.currentOrder',
    deliveryDate: 'justMart.deliveryDate',
    deliveryDateFormated: 'justMart.deliveryDateFormated',
    deliverySchedule: 'justMart.deliverySchedule',
    deliveryScheduleRange: 'justMart.deliveryScheduleRange',
    floor: 'justMart.floor',
    idDistribCenter: 'justMart.idDistribCenter',
    loginType: 'justMart.loginType',
    neighborhood: 'justMart.neighborhood',
    noReload: 'justMart.noReload',
    orderId: 'justMart.orderId',
    ordersInProgress: 'justMart.ordersInProgress',
    payment: 'justMart.payment',
    phoneNumber: 'justMart.phoneNumber',
    product: 'justMart.product',
    rapiPagoInfo: 'justMart.rapiPagoInfo',
    tempNewCard: 'justMart.tempNewCard',
    totalQuantityProducts: 'justMart.totalQuantityProducts',
    saveAddress: 'justMart.saveAddress',
    showOrderProgress: 'justMart.showOrderProgress',
    substituteProduct: 'justMart.substituteProduct',
    user: 'justMart.user',
    userName: 'justMart.userName',
    userNewPassword: 'justMart.userNewPassword',
    version: 'justMart.version'
  };

  public static url = {
    admUrl: 'http://localhost:4200',
    adminProd: 'http://192.241.171.157:3131'
    // adminProd: 'http://192.168.0.89:3131'

  }

  // (+) Keys
  public static keys = {
    googleMaps: 'AIzaSyCVykQ0HJqkfAvjsvD6Favd789zuFL5qok',
    totalQuantityProducts: 'totalQuantityProducts'
  };
  // (-) Keys

  public static endPointsMethods = {
    users: {
      login: '/login',
      changePassword: '/changePassword',
      recoveryPasswordUsername: '/recoveryPassword/username'
    },
    adherents : {
      getAdherentsOfAffiliate: '/getAdherentsOfAffiliate',
      addAdherent: '/addAdherent'
    },
    affiliates : {
      credentialCuil: '/credential/cuil'
    }
  };

  public static roles = {
    user: 'user',
  };

  public static quantity = {
    add: 'add',
    addToCart: 'addToCart',
    subtract: 'subtract',
  }

  public static sale = {
    code: 'OFERTA'
  }

  public static token = {
    code: 'Desarrollo_112021'
  }

  public static contactInfo = {
    phoneNumber: '03518750077',
    whatsapp: {
      ios: (nombreCliente: string, numeroCliente: string) => {
        const mensaje = `Hola, mi nombre es ${nombreCliente} y mi número de cliente es ${numeroCliente}`;
        const mensajeCodificado = encodeURIComponent(mensaje);
        return `https://wa.me/5493518750077?text=${mensajeCodificado}`;
      },
      android: (nombreCliente: string, numeroCliente: string) => {
        const mensaje = `Hola, mi nombre es ${nombreCliente} y mi número de cliente es ${numeroCliente}`;
        const mensajeCodificado = encodeURIComponent(mensaje);
        return `https://wa.me/+0543518750077?text=${mensajeCodificado}`;
      }
    }
  }

  public static contactInfoBA = {
    phoneNumber: '01176545400',
    whatsapp: {
      ios: (nombreCliente: string, numeroCliente: string) => {
        const mensaje = `Hola, mi nombre es ${nombreCliente} y mi número de cliente es ${numeroCliente}`;
        const mensajeCodificado = encodeURIComponent(mensaje);
        return `https://wa.me/01176545400?text=${mensajeCodificado}`;
      },
      android: (nombreCliente: string, numeroCliente: string) => {
        const mensaje = `Hola, mi nombre es ${nombreCliente} y mi número de cliente es ${numeroCliente}`;
        const mensajeCodificado = encodeURIComponent(mensaje);
        return `https://wa.me/+05491176545400?text=${mensajeCodificado}`;
      }
    }
  }
  

  // public static contactInfoBA = {
  //   phoneNumber: '01176545400 ',
  //   whatsapp: {
  //     ios: "https://wa.me/01176545400",
  //     android: "https://wa.me/+05491176545400"
  //   }
  // }

  public static downloadLinks = {
    android: "https://play.google.com/store/apps/details?id=iot.app.justmart",
    ios: "https://apps.apple.com/gb/app/mart1010/id1483121410?ign-mpt=uo%3D2",
  }

  public static appVersion = {
    android: {
      code: 65
    },
    ios: {
      code: 65
    }
  }

  public static orderStatus = {
    created: {
      code: "CREADO",
      icon: {
        ok: "traking-check-finish.png",
        pending: "traking-check-pending-finish.png"
      },
      order: 1
    },
    pendingPayment: {
      code: "pending",
      icon: {
        ok: "traking-check-finish.png",
        pending: "traking-check-pending-finish.png"
      },
      order: 1.5
    },
    confirmed: {
      code: "CONFIRMADO",
      icon: {
        ok: "traking-check-finish.png",
        pending: "traking-check-pending-finish.png"
      },
      iconB: {
        ok: "traking-check-down.png",
        pending: "traking-check-pending-down.png"
      },
      order: 2
    },
    inPreparation: {
      code: "EN PREPARACION",
      icon: {
        ok: "traking-check-down.png",
        pending: "traking-check-pending-down.png"
      },
      order: 3
    },
    onTheWay: {
      code: "EN CAMINO",
      icon: {
        ok: "traking-check-down.png",
        pending: "traking-check-pending-down.png"
      },
      order: 4
    },
    delivered: {
      code: "ENTREGADO",
      icon: {
        ok: "traking-check-down.png",
        pending: "traking-check-pending-down.png"
      },
      order: 5
    }
  }

  public static platforms = {
    android: 'Android',
    ios: 'IOS',
    web: 'Web'
  }

  public static bodyPost = {
    addClient: {
      Nombre: "",
      Apellido: "",
      DNI: "",
      Mail: "",
      Movil: "",
      Password: "",
      Calle: "",
      Numero: "",
      Piso: "",
      Barrio: "",
      IdCiudad: "",
      IdProvincia: "",
      CodPostal: "",
      EntregaDia: "",
      EntregaHorario: "",
      FormaPago: "",
      Estado: "",
      Avatar: "",
      Token: "Desarrollo_112021#2221",
      TipoLogin: "W",
      RedesId: "",
      DatosEntrega: ""
    },
    getProductsByCategory: {
      Token: "Desarrollo_112021",
      MuestraSinStock: 1, 
      IdCentroDist: 0,
      IdCategoria: 0,
      IdSubCategoria: 14,
      CantInicio: 0,
      CantMostrar: 20
    },
    getProductsSearch: {
        sCadena: "",
        Token: "Desarrollo_112021",
        MuestraSinStock: 1, 
        IdCentroDist: 1,
        CantInicio: 0,
        CantMostrar: 10
    },
    confirmRapipago: {
      Token: "Desarrollo_112021#2008"
    }
  }

  public static pagination = {
    searchByCategory: 20,
    searchByText: 20,
    searchFavourites: 20
  }

  public static locationRequest = {
    title: 'Solicitud de ubicación',
    message: 'Por favor active su ubicación para mostrarle las ofertas más cercanas'
  }

  // (+) Login social

  public static firebase = {
    webClientId: '1019453075631-8ppor5fp0koj38ft311ipob7eenkj1qq.apps.googleusercontent.com'
  };

  public static loginSocial = {
    types: {
      apple: 'apple',
      google: 'google'
    }
  };

  // (-) Login social

  // (+) oneSignal

  public static oneSignal = {
    appId: '17b1107e-16ee-433d-a277-7b7ffac0a925',
    googleProjectNumber: '1019453075631',
    WebPushSDK: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js'
  };

  // (-) oneSignal

  // (+) Keys

  public static mobbex = {
    publicKey: 'aDkKCIpBdZeW2v5hp5RktnfTOjN_xZCvoJ~H',
    /// 2024 Enviadas por mobbex
    // apiKey: 'YPuM0Rm2AQqKwuZNgIOHv~9oI5JM~8ajyhSZ9Irt',
    // accessToken: 'a7a69646-7f9d-4bc6-8b42-a606e496c020',

    //Posman de Test ( devuelve intennt.Token)
    // apiKey: 'zJ8LFTBX6Ba8D611e9io13fDZAwj0QmKO1Hn1yIj',
    // accessToken: 'd31f0721-2f85-44e7-bcc6-15e19d1a53cc',


    // 2023 
    apiKey: 'xQwdhcwu1jiGdsASb5Nj5C6fHkXjpfMFHWNg7shh',
    accessToken: 'fc78aad4-8ef5-4e99-8954-d840d8f28c62',
    createCheckoutUrl: 'https://api.mobbex.com/p/checkout'
  };

  // (-) Keys

  public static k = {
    k00: "½`2'â)£C~[_”ÿÙ#ÇÂ‘ÎtÌÏÝŠmJ(;I*õÍ•á»–¥ëòºHÛ]Ÿ’Oµæ×ãh.k,¶¯Ú¦éi{‰XúÁE¸—PÔ±5œŒ†T¨î^u¹fùÜ®ôÊÅ÷<VQv©Ø-Zàaª}¿ÕMK¢F&3öDR¡ÈYGNcyèLü™øÄþËäÑ%+wïqí9šr‡@¼§xì/êÞ¾BÀS€¤Ò0leÉý«ns1j6:zW?8b“g=>çƒÓ „·Ö…Ðp4´ðdß°!å³²7ñ¬AÆUÃó$|‚ûo",
    k01: "è4ÔïÏyçg@Yúò‰µLo ÃíFBŠ<éýØfêöæ“v'É-/ZpmR¶wÈOx1uÍÕGÚàN`s&ÂþÑ:dÞ¯Pz,²}jáû…T‚(?>‡7U=$6Ì£)üÀ§3ÊMãi÷°Å†Æâ»cÁÄùE©ñªeî¨×ŸVìÿ‘€ëKô¼D|Îõ±*’#„;ÛƒÙCø[8—{rAåºhÓ¤~J¢œÝ%0ÜX·®–.IbQ5qtäH]´¿^«¬½_kó¦¡ÇÖ”Œa2Ën!W™š¸9¥ßÒ¾¹³Ðlð+•S",
    k02: "„àÑgæ5NÆEQÈÕ3J»âÖ*“bð×%/óÅãiñ=†á€ûScBß#¶Îå¨§:7 ?ÒZrìÍvüÌRéï•öÿ™fG@÷¦ô¹;ú‰hÝ¬qÛ£pk4¯ýù.PòI9«Þ`Ü!Ê´šØ-6Mu¿)‘(l^y&ÁäC2UÄ¡ƒÏÚTŒ_|FëaþÀ¾—+,zÂ[LøŠ]ºo0¸õAª°³ËÃtVmÙWYÉ'X8Çí–>·DO¼±¢H1Ÿ{’jµ$Ô”Óê¤œÐ<©½‡n‚e}²xKîwès®¥~…dç",
    k03: "a—ZÐÒÚgïÄf_³çÔ•Ë'–P°l¾¬·þñµ,c¦|9Öî6¸…Ì>KøÁœ=òWsy:Å‰÷¤¹»]Ç^w2e¥0{Þ` +ðöG<‡Oí3n½IARƒ«u/¶„}ûX1j‘M(’äèx%qbÛ†@zõÎš[Ï¨8å¢ELâŸáhôÉJ;v!TkCß)´NpÊQ$Ãýãó&€ÈÂÆ‚ÍªiYú#UØF-o¿?d™4“ÝüÓSùìÕæŒé£©Ñ²àrº±ÜtH*BëŠDV×ÿ¯ê”¼5§®m.ÙÀ~7¡",
    k04: ":Æ+²‰_GçnÈHá#£Â‡D»–X21Rƒ®Îœ*ê¹z3;%ÃQZCÀ?P¾!„ÄæïqÖ|am6ó}<ÔÿÜÛJt…í@öj~þYéoý¸±hâ'Ew/y¦l ‚°V¢™)µßxv¨=ºËM´ŸÝØü¡^b½ªÇK7¬åìòiB§€Ðkf(ëúèATî8Uø¶s&5WcŠäL†ÒO¥Á·‘,×g•Å>e©³N“¯ûÌ’9÷{rÞF«Õ]-ù$4¿ÚÙàÉÓõd¼`—š¤ðÏu”ñ[ÍôÊÑŒS0.Ipã",
    k05: "ô5@Öª7¾¬ÏóÝ$s'»C|¯ð¸M*9ú’to_µÅ:Šb©ÔN]8{Êá÷¦+^ùEnæTîÙ¼´àÞÇ[ƒë£&äÍ±QKßê·¿§€Ë#;Zu?š2—¤Ò† ŸiÛ™ïfüŒèíWJ/„Ø6‚²y}vYâ½®kÑSÓBø¨x¹V…ç<!Ä–×õ«UöþÃìR‘zXhPÎû¶HGjr°1Ég”p0F(³ý“.deã~a4ÂÿÚÈ,ºñ`‰éwå=ÐÀ¥)cD¢Ì>‡IÕò-Æm•œqlA¡LÁOÜ3%",
    k06: "C’Ï¿ï‡Øä”õ•?aÄŸË{µüáKÎuMì´Ækþƒ®Ó*Ðó‚9åðyÞÙV3EãÖYÌ8“A‘1Gb }ºëœ±lÁ€>4–¦TPç¬N|)Úô7§véQ×pmcŒB^+Å¨2„»¤h¶ú[6t-«ÃfíÇ™îU—³¡/ýªLÛù~nÈÊ0ŠÀ¯ÑgWXSi$ÉoÍ]£wxèF&Oe_ñà¹Õ%øš‰`Ò…5·¥DrÔJ½¢:#!HRq¸s†÷¼=°IÝ©(Zöêß@'²¾<æjÿzûÂÜ;,.dòâ",
    k07: "²xŒØ&Dié`¹Q¡-çÈ+úuKþO´÷Ïë©¾ÃV–8g#9U¸Z«@ä}Ê”ò’FŠ®Hcök2G¶óôÚzÆè|Nã“‡°/³)Ðo€4ù™*ƒ¤»El'.P·ª=‘BÅŸ¦—Évjµnû‰qb…ýÇüaÌAÕp•CÙ[Ñrå1Ë5$T±L Þ^J~%Ä;ðÓytÁ„ÿXfáøh¼Y!_šæ3ñ7:¯Â<×w(0]¿§mâÜWìÔe¨œ£ºíI6†SÒMà{?‚ÍÀ½¬>õ¢sRd¥ÖÝßîÛïê,Î",
    k08: "í6öµ¯¨j×Ë{dh=ñ’c,ÓJå:Q¦æ¢°Ä–uyßw$½NIlTÎŒÊOBè#•Àã/1¸V¾»äø‘^+gÌ(`|êØr}éÐÔ7þ@KâD¡WÝ‡Hp“fï>®!«´*ë803zLÁCo÷—'çÖ„ÿûýS&ÂàÜÉZ;¶²?Ÿn…£™ðkbÑ¿©t¥îUeÛAƒ‚q2]ôõó†ÍGmÒÆ[ÃiRÕ.%Ú¹òÇùXFü §¤)¬·È459ŠšaPvá€<úYÞì~Å±ª_ÏºMœx-EÙ”¼s³‰",
    k09: "•Þ%§Ê¨Ë&7B´g†ƒÚï¶U²þ½ÐIÄ:„@ú1ÈH®NCií6>çŠ¿^ÅSä,ý)à=æñ‚Øs.ãr·áw¸êÌ_ºµébf—šy€ßO{œ™ÂðÑtÉcîmT±Ü[Œì¾ªzlJ×-‘ÎÙ2~”¹£õWX]âK`FÏ!¬?'$Û<Akè¼°¢Æ/Õ÷GÁÀåòÓRQZh}E|¦P4ÿa8ö3øDÖë«óx¯–0’;9“M©uqüÔÒû#5ô+Ã ‡dÍ*onŸYÝ…³¤jp»VÇL¥ù‰¡(ev",
    k10: "Ù7*Î¯’å×£öMšçû±Aº…ãÖµ‡5ÜØ<”¢{–Ó2ï¨·Q¶ƒUWÛsÒÿa„ÁõÍÔXrP¦kÄ•—§Ú¤€%æìÊHÞpgfb¬ý8B}(´ox¾)`1CYðj²'9e½#Iú©á¹é³¸ŒÃ™ÐÝî3uèª|Æ,»>:lm0c~nÑäGÇ&ßDy4¼FKëËÀâJ«-;É¡¿Âv†÷þ¥ôS+È?=tüŠ‚$ê h‘.ÌœNL_ø[à“ó°ÏVwùiñÅR6Ÿí/O!ETÕqZz]^‰ò@®d",
    k11: "°Ó5²—ðº‡œ0¬“$È¸dwAz:D&Ãl’B8âax‘Ø(7'/¯kR{<cM-÷4~åJjÇpÔÞÿXô2ÕL…GÉ³óùIoÄÜftg]Ú*±Ö>ú;ÅŠ»þÎ×ûè•¼šPà‰îç©HÛ_®ñY§”«E¹òø†Ýêv6á¾¨rÁh£ïƒ^9µeK,¦À m¢[Ë#Í¥OÌUNVÂ‚3%¤íßsé=ªTõŒ„n}äÏÆC@+bQãiy¿!Ò`u–´ü™.ÙÑö¡·ýZŸ?)qìæ½ëÐWÊ¶€1|FS",
    k12: "…H;ëò—ºí«So'rÄÅCqÍ5È@U9¬±îdÛ+>ÎJôeÓu‡§sV84‘cKMXÚï¡0`.÷_á(ðTÏäÙGt¦j^¹$¿“%ç°Ihœ†[Àa¨wË¯€6ævŸÊ}#¼¥¢Eþ?Ö=ÜŒb)Ák BÿØA²ßLêà™ÇP]ì”½-–¸¤~éóý¶ªü´/ú1‚ÆDøgQùZƒÌÃû{„yö©,·WÔm£Y³&ñµ7×õ:èšFzxO*NÐâÞÂÕR‰nãÉ!lÝÒŠi3p»|®Ñ<¾•f2’å",
    k13: "Ê4Æœ¼ú,KËT©Pöaó|V]ÛõÓ$bçìIîLô§µªÞ5³½-QŸÁwÂÚ}†SAD*¢èOëê’–Äk¶t<øñá)Àò„B&Je{X·«Œ´(>2¯= g‘ÍÜÎ¬°suÅ—CH%™fÝâd.æ•þÑ®xƒûŠ÷G6…‚äo¤_NíÙ?ÒEjn'Z~Èÿi“€8R‰šÕWý7é¦¸ÖFz¡¿c9q0!å£Y¹ÐUï+Éß#×»¥;ù1lãh²ÔðØà@ºyÏrÃ`ÇM:3¾±mp[^”¨Ì‡ü/v",
    k14: "Ùôh'°e><$²¦®ÍRÄõ-ãnË8×´»S¾øÀàAé…aÅ‰ºÐzw+ï¯ä¹EÛo™[@p‘ƒåOLmßJ?ùî„Z#çKg4† I_)§¬ÿÖQ¡¥êš‚ÜÉN/÷âG£‡Á¼ñæD³~ÞÕá3€ÑÚü“ÔÊ”íCFuWþÇlVýq¸—¤Yì•ë¶Ïµ©Ò|(;È}i*Ýx={«PXŠèTª1`Ã6]:±vjÂœ&cö70UØ¿¨BsòÎ9^M·%Æ–¢’,Óû2½5ófðúHkŸŒtb.y!dÌr",
    k15: "!5*¢Ã“”Tí^™ŸIg·ôAø½ËÆm<3³ÎÂ:‚âcr-Òã¶‡¼4{0ïiûLÖºöÏî/ñ‘¡¦>òy7@u»éœÀx?€q8þ…;Š=)¾ÚÙV&®_Ü~ÝEv2¨oFM¥}ì–BÌùÓÄåª´D.,É(Jç+b«hè¤¸ëÁQ|¬]s1 ýkúPµX¿ƒfGáO—ØÈ†[¹õ$àÛ±‰%ÿÑšÇ`aÊÔnYÅß9ül¯£ÍtÕZðäÞHdÐ•e§#Œê×pw²Cj÷°W'U©S’NæRóz6K„",
    k16: "#ÎÌ&»u¤«ÿpFßn¡z±ETU>ÛÁNPZâ'ÀÚ%KMsIê—™Å·qÞ²7$:ª(ùQ¾¬@!üšoSjà½e¸_ñ*ûmHÆÇã9òôkºúáý0’þÝÂhå¦c1fÄØ[ë43÷èJ6®8€{ƒ©„¯´Ù§³.æ¹/ËCÓv5È)ìL}–îÊ‡¢‰Ã¼œA?YVÐÕ°‚É† ÍŸbrlgóxaÒé^ç¿Ô<d¥”ïGW|Ö•~RŒö`ð]OäÜy¨BõÏ2£wí¶ø×Xµ+D…“=Š;t,‘Ñi-",
    k17: "SDùÐÙjûl—d¹$õwµENÔ[ë&cYÏem“bÂÛ¼£Š~ªTóÒ®ýÉkîIUÓ†<©Ìf¯éØ9Zè¿5òaƒH’íŸ2WuâæAJLÅ¦}œÇnô«‚-h?Þ#yúÑŒ¡üt‰o|ß(gÊöx·™¬0…÷þñðÜÎ]@)4”¤>1¶,±€‡8sÝÄêÀ½å{M.¸=×vGVàì+'„%áº7¥:´šÕqRã¨ïä^z!r/X² FOç36§iBKCÿÃ°ø*ÚÍ;Q¾Æ_PÁ»ÖÈ¢³`•Ëp–‘",
    k18: "ëøxÄFf¼~š†;¢·®—ÆEïéA²êòD6ç”}Pl,|ŒÜìJjyÌ7NŸQ¦K½VáÎ/ÿ¤Mß»e„Ð{¸0@X¿LÃ_ËãÛ3¹ª©`ðhâ•r¡gYúÇ€ƒd¾–ñ?2óR‚ýk:Õ³ín$!Cå&>±Òœô%ÞùÉ¨§oºØþü(ÊõÍ¶°´Hî«ÈÏ4W]aIÝèuOvæä5'Ú.ZtŠUp#¬öÑmÖÂi[-T9b‡Å+µ8c)^‘‰™SÔÓB£Á<¯zàw÷¥û×À=s“ *q1…G’Ù",
    k19: "æO‡ëH!£Þ…ÆŸNîç÷mò}Ý™YýeP0<¦v•{éûÃ¹=ã7È¤ÖÜÎÂC»^Ma’‰‘½ÅgkbXÓzøúhŠ¯u¥Ù°²Z—)+ÕÌ”oSi¬Ðµ8cFóQVÊKƒ6š„öå#Íáèõ¶§tþGxºqf2Ë.¨œÀñ|ü[3¢DIÔ-©>J%–AÑlÿ€ä³'ì_ïŒ`ù¿¸dð$âwy?ÇWU*ÚL®(Áí¡·~¼Ò]5rj:¾91pØ@É«,sÛT;´4× /RôÄ‚†B&Eªn±ßàê“Ï",
    k20: "}!ÞÃ¡¿ìV|Td—¾„ñP_™ØUB¹(•±ô½°šwï–N«b4÷W¯YéÌ…ûkäJq2†þÒ*nÐ=.ª%Šrò^Ÿ0¥á’¢×îhçxy1$®9t#]/Xo¦”‰‡£Ü?RiLÑùê,‚ã5[ýƒøöF>ÿE¼åõµS;ÓÔ¶âAËzCæuœÁsÚ~»QÈŒà:Î+ÏHß-e@³GjÄIÍv3íëÀOÉú7ºm€©a §²ð´)è‘Ù¤“'póM6ÕÝ¨ü{`ÂÊfÖlÆ8gÇcKZ¬<·&D¸ÅÛ",
    k21: "JòÌOj¦(ÈCeÄ¸õ–i>ã¡«’Ðød@ëÎ¿ö8‡)?ŒnàbÍË½ÃÞï4ù”swBú±qÿGâF=€¥z¨N_É+ÓÑü:ÔÇ-®ÛYÙg}çLZ&mKT.ß¤!…kŸ7M¼éÊ%óRa^—|¾1º]pÀ„~‚¬£`PyhrÁÅoá¯‘2$ÏÝ9Qð<¢ UE·ûÂôtl•[c/íAv°6³æŠ#™‰î²W{§¶,¹x×œÕ3†šêµ*DSVXþÆf»ì;´ªåýè“Ø©ÖÒÜ÷IuÚ50ñH'äƒ",
    k22: "é´Õ”[¶¡#F¬i6ð8Ãw.L“=¨Ù)j2ÅÁÀÛøòrI®½fþ%g(’œTCNŒ9ÉÎ{a»ìæ-äk¾4ºöŠÊvB‚|ÚÍØ§·mÖçÇ–W²Ë¦à„V'!Â†bÒZ^ôA}¢0Ñ¤uš©Ï¸úƒÈ —êt@èü`R7yO‘JS:í™€>£3Ä¥åµñÜ?Ô«c±H]ãâhP¼Þ•ûKd°Ì$X_‰ë;×‡l1³nîY¹Ý,/*…Q5D&<Ó÷¯ý+GŸ¿pMªxsóùáUßqEzÿÆ~eÐoïõ",
    k23: "ËVßYÅF™ì1Ó@‚9£3ý«x`O?’n*d¶ÛrÂ¿!ÏD‰”Ê¸®ô^©„Ð/a(q‡KuUeA†jº¹òÇ¢Öøš;³&M0µÉ×w,Ø·ŸQz.Ãö üB~tÄ2Zú€ñªJ-²¦%Ú—påÀà<$¥¯7Î§‘¨>fŒP5¡ŠùCÌõþ'¼ð»oGNÜ°ÆÈÞ{é|TÙëc±v=m•í8û_6glÑbk]âH¤iÁçILhyÒ…á´ÝX#4}Ôƒ“÷¬+sS¾ê:îãäóœ½ï–RÕèÍ[W)ÿEæ",
    k24: "FQ/')Nh¯Œ®ÔV¦vSÍxíõÓÞ!t©d´Ñe70È‡×÷½;J1<»WñØ¶òóa=?äg+jÿ|ŠƒTCÖqç RKüXºÆ“ïP§¼Å·—á’*49AÎµêUE€™G„nûY.ù(Òz@é¨Ç¹:ðö#5L^%l¬•c3°ÐàÂ&b¿æŸß_ÌÚÕošÄâ]IúÊ{wm†ÜÀ>B”sÁr…Ý¸ý$}ª«ø¡M`f¢2ZD±²£Ûëku‘œpå‚èy-³¤ã‰6Ëiî¥[ì¾,Ï8HÙþ–~ÉOôÃ",
    k25: "ë«?1Í:€U†%´‘M{ÁDæxåik”çŠ.Ë¿}/ÏÒŸ¸½ðB•g;êœ·’_øÔ|áíØjäRe5EÕ¬Q¾ÌbÅ¹¨0èÈ±q4c=$h³õHªlW¡îL!“fÇYÎÂÀÓþXzéšJ‰ò§¤²K—3 ûùß~Ãö)n¢ÿ©d]O^°>t-ÄCïƒÜºFm[úÛ`ìa'w¦N×ŒÖñu&–(+8à¶Ú7ÝÆóÉâ‚@¥¯Ê#rP™IyÑV*oAý6Þ®üsZpÐ»,29ôµÙ£Sv‡<G„T¼ã…÷",
    k26: "gÎi÷ø0æ¸·(„«^QþÝsÔoÍfðNÃ¤T™[òï1#kd‘ÓqÉY&ä<üaIx'>@ÊŠ`Z{Ÿ+AÞá¨Ð¯’¡;=šíOªûÇBW! C”eñyå/ºÀ?*à]Å´XÙÌ¦4¿_ƒ,êw»GÛvËnéÈRÂ—Kµ:|SÆrú¢.LÿÜH%VßÑ-€5z½2Ä°u¥³MhöÕ±Øîù†cU$¹‚×l~–•E3‡¬Á¾9)JDõãôÒÚëŒèìt£“}©…b²âFým‰7¶ç¼Pœ6§Öjp8®Ïó",
    k27: "$)ñ¬I…óÉ/’¾#Xë61«>%ËÚW·bÎð.ÙßÃ]áÂÈ§D@9~+ì©nKf”Šù5L÷'è³òV_âÝ0µCÖ¹•OÔ`c3q7½=ýg±º‡|†a£ŒÀp¼Y¿}{^Z´4–yÅ!Ìê¯kØNlEsœ(úÓªi»ÁoîíÏüä¢UFJ‰:²¸?GMe2 <Bÿ[¡þh×€dÕï,¦ûS&Q‚H°ÐƒÜ®åõÞ-‘é„¤w¨x*šRÛ—Êrôtmz¥Òãöj8ÄÇuæ;ÆçTŸàPvÍAø¶Ñ“™",
    k28: "vBÀÚqÔØMÒ2âí%mô·DÃ'FülsO=²5¯$btÓ¥Ääæj}<Î³—89-yX7Ù†É6A‡(pHßo0rð„ÑN™TÏWùáç>¬…¾ö[eÝc½¢¦¹×µõø€ué¶fšÛG óº¸ÿÆnŠ.«`û)Â“ë¨°’þQiî_4ÐYx±´§ãÁ¿@L|R;òÈEaœ#å‰dú/©ñ–‚,!J‘ý®Ÿ•»I]êÍì?C¼à¡*ÕwŒƒkÜSÅV1ÖËz+ÊZU^hï£{¤÷ÌÞ3ÇªK~P&è”g:",
    k29: "DÿµÝSów+xðIi¢8çüÃZ%g2Çåá£ìšÈ&cƒËß€;0¹Æœy½ÓÙ>¥(Jv“Q.qM‚<ýã„,N?af-’®ÐÁ±ÅâhÖ/@º*PTÒ1o¡lRÚ3í«V=m¾[ë`j_‘GKøOÛ‡WÊUù»îñ6•'~önŒ!¸Àô–ÉÜE#é©Aþäê—z:†¼Hûõ³ÂŸŠs¯²Y´)^ 4ètL°”eÕØkX¤C¿Ä{Ô…Íï}¦Fu¬pd|$×Ñ·7ÏrÎà‰b9]÷™ÌæÞ¨B¶ú5òª§",
    k30: "ðNüÔ¶º–DO¬ôŸÜŠµ|!’yþ_‡Œw¾îTFí9‚ýgìa‰;´ËxëÐß»Ä/¡j°Ö·†¦>ïø Ø„—4kfÌÿ¢?õK,ãsÒ¼<Îoqè=ÏùtÉ{Ñäò£W¹™ÛÀ^«ó&P½ÇåXúÁûÓZê“SJRöÝçi¨*‘`.¥¸áÍƒ±[¤Ã©Å³®hYª1brÂ:éâÞ}•(œ5ÚLVñnM-²I$€z@”¯eC+A8Q7Ê#3cm'd)ÕvGšæ]Bàp~E§u2ÈU÷H0Ù×¿…lÆ%6",
    k31: "šGÈyq$¸ûôö°RPì¿«23kMƒ„pOó¥/*þãV¬L5ùvè´—UÝ{¾’ò@4oŠ¹¶œåÆ%x!÷Xñ»eÄŒlu)ÎF²ßZtÇá&;-±I[¯EªäWf•Ù.Ï^øSÐ?6‘:ÅB`‚=£7¤Ã,ÉQÌ_s‡míDCÚT³¼®9h“J†ÜÒ€×ÁÍ|™ÑÀ– K·üî~a8§ØÛYð½µçHÓ1¨æ‰nýÊÿÕb©gNõir¦dâÔ¡}ê…#Ÿ”é<¢z(Âwºï>ÞËúA0cà]jë'+Ö",
    k32: "ÅÔ7„¨œÁÙ¡MöÂ²ãìÐ–·>äùQøUÒc#`ÉP^?¥ƒu¦¿eèlµ5ŠÆ’=ÇO4§_&fïæd.}|Ëx0Äþ©šÎB-™w½+8åFV<î6ûÃRá3‚¼p$oÌÖn/âYßGE±º¤”Ï…yíLCç»vÊ®rJ)¶ª[ZÀ(@;iña‡ÈH~¸W¢ŒÕ¾ýX—Ú%ôÍ2³S•IN*ÞA«¹zj!€DÜ{õŸàtÿK‰Øh:']s,Ñ1ü†ë9“¯ók×¬´°g£ðò Ûm÷úqêTÓé‘Ýb",
    k33: "ØàÒzDÜ[:â†ðÚ¨Õ¸mã4HÙUMrú½!ÃÄ?VŸÅ‚5’jÑÂÝoYƒÉ¶¹š.`‡f™»«åç9%CShÿ@'ûÆ¢=Û+bEëÇŠó“ygvPí0Ô3Œ¬ÍÐ„}´BQÓªäÖî€t/Ê&)§…ü2²‘aW±{AJRË_w·æxÎ8ïô¼òÀ”¿ù#ZºÏ¡G×X$ñ÷øœ|F>—s;^ö£•Áá³‰nµè*õÈL® iß6ì7¦kdé1þ¤©êý¯lc–Ì¥¾<N(]Kq-~°T,upÞeIO",
    k34: "Þ5|~T‰>Y¨¢Mmq¾)$Q}^Láÿ°éâ¥™¸º{ÊëÙÉ%ª¼g1oFZ«³b€…kce–'aË,Ñàíw3Sãvú6Œï9ç`V<Èôóßæ?dxiœÒäÖþ-Ô²=Ø7Æ_÷© ÄŸðz¡ÍR“;/òÐB£løP§èfš4À:K#ÅÂÇ’&IŠ(®+ƒ±‘½EÝÚNAs[!2¦õ¶´t¹jH„ü]ýyîÁ»@Ï¯ñ*WD×ù8ÌìhÓJuµ”.X·¿Û•öÕG¤0Î‡¬p—ûêåÜOÃnrU†C‚",
    k35: "-¥ºá‰%¸ŠËê©ÁKÄ¬`p,äcnL€if“°ÔgDý/qQªHÃòPÑA‡øü’¯àÇâÌ–†¤¿¾SuwÆ.d ÒN³0”å+XÍ¦B{m:¡Ÿ?ïó¨[ã#ÈÜ4®bÚIŒ3Â»é²T5>;•ÅhØÿC]F¼_ÉÓ±œÐ·ÙJ*÷ÕÖ‚ZMaë(õ™9!Ýñ&|Ûy^oÏ$Vre¢8èOvxôì¶)U„ÎE´îù—}ÊR…Þ<ûß£µúç@7æ×íjG§'Y1zöþW2Às¹½šl6t«k‘ƒð=~",
    k36: "JiøéßG»QâóC:Š¡ƒrvÂD™ù;qœb,^±ÄÅñ¿…T”/#òÊ_t&@×dÖ5ýB¸nªÙõú•í8©Û“'`a2êÓïŒÆ|‡ËOe†Sæ+ÕºU*<—y²„.¢omÜûÔ¶ä’W~ws0À¯]-4kHKî‰A}[ŸÐçÃV¦hXE÷YÞÒ¼(£àØÿ§$?!j{lP%Ýzšì¥ôá´ë ÎxM€–IÚR®ÑÈ9‘c·=uþ¤½¨ögÁÏNü«¹36)Ffå‚É³Ì7¾>èÍ°µð1L¬ZpãÇ",
    k37: "hÊ¸&ü]DÔFÃ†YB)¬3È¿bí¾æQXÓÇÒ¢€”C·lÞ#uN9Œ7+–`g™óE®ôäKsŠ•«—RÎÂ×¤açZ„ÛÜ©åI.qúÕØ:0þýÑ<mM¹*nHœ}ƒºõw½öÿÚÆtèG6e|ßâpy/>±Ìck‡¡_UÁ(O;o“J¶‘jP²'~dïòSŸ!ði@´-L…,÷î³§xñ¨?ø‚25A4Ïr1zVšÍ vÀÉùÝ¥»[%fêÙ8ì¯{^ªWÄ°áãµË¼éÐ=Ö¦TÅ‰ëû£’$à",
    k38: "“¦m(²þ)iðKY¹;ùÐN÷f*ŠÃ?|½yxAhœHqØ}¨—v:•Go‘ƒ4ãÅ„9érÇÀ¡0ýe{ZíµÌgVÒ'¿sütôË¸«1ê_wÎÓ×,7†ÂÚñ°8aÿÔÑÖøŒD³/¼û¶…Êª´bIì-ä¤õÞp–Á!Lò&u 3”±§@Tc~RÙ»ºÕ‰è>î€^ÝÛBßW‚Æ©™Q=j¾EæçóÈXOáÜ¯MúPCJš<$¬nÍå¢[à¥.`k2%Fïzl#öâ’‡£SÉ·d6ëÄÏUŸ®]5+",
    k39: "Üø²Œ(F2QÝzéüië}ª¿†´õ‚‘4ƒGHÿÀ_°{Ñ*3‰ÏãŠÅ³|cqXÇïÖ¤g<¡ñRn»Ô]Úç—Bá;s)¬T!=åKËº6ØD9@fÙþì¶ò¾”~ûhYJèÓUâ½µ$&Æî¥-•'§í…EÐvSPk0mÎxö7C%ŸÒš©œ#IÁ×Ãurl/®“5¸+Làù¼·¢j¯÷dúðÕÞN:.e^ôÉ?,Â¦€¨>ýÛbw[«Ìäp„OÊÈóo’W`AZÍ£y™tß± æ‡1êaÄ¹M8–V",
    k40: "‚r•óïáòÝçuÊƒC©™[¿|+‘¡!]ûú#ÜÉF7KÞLt¯9zs<ìù)¸œ¨ß}0ËÚ°Ûb=RN½V¤g’/P1eBÀ“Ó„`^¼àT³%é»º;Ÿ«ãñ¹Q¬ŒÇè-a–$¾§ExÎøJ{?ØcdÒæý®êmoÕj3&@k ¶4DOålÆfÖ£5Z×µ¥Šë¦â*÷Ã6ÁU_‰S·'nišqWhÂy†ÏA:ªIÌXð²p¢ÍüÔÐ8öYõ—îôÑÄ±íH(,.vM2~ÿÙþ>È”€…´‡äwGÅ",
    k41: "¿{$éºz©hëmìþnÝÖí6Z»åxN(#e+7ËÃI4kÚsœ^?ö*C,÷ªÿ½ú_~gÄ¯ãô¹@9ViêAXÆ&=±æyYï°øoÜcl’ðQq‚”:Þ1Å·P|Mu3B>5[¢Fè¾Éñ£¤…¦‘Ì¼ó•²„.tbµ;‡üLÕ¬r%DáHvÙw´¡d!Ð¥Ø}Í¶Ÿ<¸`ÔŠ-]îŒaSƒ×0ù'†Âf ÎÛ/ÏàjçOK¨ýR–ÁTJ«W“ûßGÒE§Èâ2‰8Àä€—ÊÑÇ³Ó®šòõ™pU)",
    k42: "_æ”ÉÎËÝ$ú‘ùâÑcHô<eøœØjÓ,¾Á…«öð¹Æ—Cíõ65/@¬^óÍWºd†:2‡*àsYy;è!’Åz±FÙh€£Jt7-Ò´aß»åfû×ê[1B ýZÈ™Uç]l~ÔÞîÛg3°{Ì³Xr0½mòLìªÂO¨üpµñ–S¶Š.(ÚV§„šá}EÐb©P÷ã'“é•Ç¡R¼K&ÏÕT¥¦ÜqƒnÖDx¿4À)`|+Ÿ8þ¤äQIu?#i=²ï¯Œ¸ÿ%‰k®GëvÊoM‚A>9¢ÄÃ·Nw",
    k43: "‚“2SœÓR¥®¶KÙNÏeÞ)¨Mh”ç-AÉøð3.[à~fƒ]¤á:¡mâ…#Ø+VÂO«ÔuÄG^ß4ìÛH†gx!š ¼U•jvÒtEÌyZ*P·9r×ý©Iÿ0æd’ÖüèCûê÷c{Ê™T¿1§À(,%ë}'ÎãŒ¹¬>ÝsïäW5ŸÈÐö„<±=»¾—Ã@XíBÚål°þQJo‘²ñ£$´ékÁ¸ºFó³ùnzÆ‡€bi8Š/õ?–½DòÇî¦_qw;Í‰ÕY&6¯`7apÑ¢ªËµúÅ|ÜôL",
    k44: "€?;!ò’µôé6sÀè*O:°±¯cíª#%gtåÓ/Y‘âfZ(ï@úx“ö$ÙìdbM<1„ðá4o2ÆÚß-¤—†ÕQøç”L‚Îq=¾,u¥i²æºBÖÉ‰IrÞ` N^eaŸü&¬«îGƒ~×ËÍJ'§jv+lêÄnSÛ¦Ò>©¨Dà{ñÈÏ¿)ÝÿþÑm]yU÷p´V¹0ãKýÐwÜzŒ.ÌT•®œ5ÂŠ‡õÇ9ÁóØE¼·ë¡Ô…_ù3[»³¢HûAR7™P8¶|}£X¸ÊkÅš–hFÃä½WC",
    k45: "V÷Ë™X(·^‚úí:K+´¬Aöß/[×4ØŒ6!¾tÞŸë¢lxº~8dÈè‘ðy§P£%2¯»UW•ÎmÓµ;¥ _”5Áø03Âqó³Šo9æQÊNþÖ¤ñ«ZÕ¨‰F—fü¦‡{C“€OÙeîh]šÚ¶@¸Ñ7DgÐa©êì…-®'u¹=ä`çB¿Æ±ÛSL>|†jÿvnÉJïÄ²ò)œ’I}HkwEùÇGÔbÝYÌ?Tâ$réã<p¼Ò¡1°Ãô&MƒàRiå#cáÏÜsÅ½ý–z*û,.À„ªÍõ",
    k46: "MDÚGÐ$tï¯ƒNŠ¦ÉeÊH(J@¬9Ù=’·)¤ÁV2]_‰^;Â÷%8'~¼“¹SÎAÞ³—+×vxjýâÒ{Qñ…‘i„†ŒUX½µ™pÇçûofaÛãwíäå‚´ÖðnÿsB`ªÃÆyF}|IÓé•O±T>£”còÏîÍ:KlºC[æd4¾uêø«Ôš-ZÈr,?²¶‡Ü 3ó¨!kÑüqÅ<#Ä¸Ÿô°.©WÝm¡úhÀ6Yg¿þö&1õb¥Ì0ëœà5€*§z¢èË7»ÕáP®ùRE/LØß–ì",
    k47: "gwFïúÜöÝÕ“†]ÿM,Š´<CýT§¤º`ñ®ü5ÐÑƒ)¸4äG”ÆÃ¬s~ô·Q[H8-dy£ÊÏ•–¼Ì$¿Z;/UóhçŒqakl{Á}AÉtb©Í‡¾²ÂÚŸËÇxþ=m¡!'Ôe¨áÎ^Ö&P¶±VcÈ9š(nÓìÙ½N³÷ùDßKëSRLv1Yœ.Àå…¯ði—ãz_0XOæuâW>oûàj òêø„7¹+Ò6@î¥*¢’2f%Å°‰3×I?B#é«Ûµ»p‘J:õªÞ‚è|í¦ØrÄ™€E",
    k48: "12't–bé¦c§Ê¯pF<q&u6«áŒæQ=°S©¨¹Têñ5´‡lOL(Ó×£m:?Ñâã†)ïy¸¤GPZ½ìK789Ã±B…åAƒ/}h¼H[V¬CŠÉÚû³g€e“ØÛ~NxÐ.Ed^zªÀ ÎÇ,µ4ÖÁÄ—%iöèI*_#üÂ„fÏÒ¥ËwÿÆí¡¿øÙÍäJ”·M¾œ®;!+‰²Ü‘sÌß•DÔ-nšëºîaú¶`ýÞkùv3X]þŸr|YÕ™W÷$»ó’RU¢ÈÝo>@Åô{à‚òð0jõç",
    k49: "üfV{ÛËÈ†§‘zúölD®y;bÇþqîMÁ¤~ë_1%¼>¦²«2ìÊoíÏ™/ãÕðU–¿+¶¡¸óØÐæJä•´å„Ý…Œ|ª³IGêjhtŠ=Ó'4¹gn.r$]£?û½âTá»v(<ÚàÑ¥øôÿOP6ÂCKd8skFaõ3ùïx[¯÷ºHSèÒ¢òp,wÖ)cÃ¬&—¾Qi9‡Z‚5Ÿ!X·` ÜÀÎ07A“}µWÌ’ñ:BeÞœÍuÅ^émE¨L€°Ù-×ßƒ±ÔÆ#ç©šRN”ý@Ä*ÉY‰",
    k50: "þ—ØwdRõßXŠ!%ƒÈæö‚(Î$U#âD¿¼’¾”H.-T×½ôO|kl^s°?¬aÞíü5Œ®øîáÔL{GE€»¶§ ÚœeS@ê[]³;,C'jèû`ÌJ<9Â¤uW*Içñ8„¢¹ªtã¥KhÜð7÷ŸcÓËï†‰y¨ÐÙéÿróPvÄ‘òÖÑQ+_3q–À4«¡Å™2&V´Ã6Õ•MNÝÒìiýÉ©àëù>1£‡p:gzfµ“·0ÆBÁ=Y~å…nb¦ZÛxÊmoAúÍºFÏ}ä¯²Ç¸š/)±",
    k51: "ágrñÒ½zÈ€m²«$y~—Ç³VÏc}©‚¶:[í¾l/„…DuÜ%æšƒXï÷UN¡ºôBÑò*)(>#Ð5jCf=nd¦»ú¸–åÖ@-GçIüEt^Æ¿±‡!îóâT0®O’SÝÄ™R´F1W¬ãËœYMì°2pŸÓHÿàQ3ùwéi¨<]{o¤8ûL6þ_“‘A+9ä¢ JÛh¥¯KÔÎbÃÂaê¹ÕÍë4k¼sPÀÞ×ÁµZÊÉ'•.Šex”è&ýð£Ø·ÙõqÅø;`Ì§ªö‰,Œv†7ßÚ?|",
    k52: "qŸœ|âg*f+ÿs¬×æ2êË–OáVn½«¿¶Â—¼”E•Ü‚TYÈZA¨år.<Jé!Dô0S†Œe>ö¹Ýš^[ã“´™ì?£’¸5ÅÎúM·$ûuÉc]/lmHR¯§…À³‘jÁ-Š(ÙIPvÇ=®8¥ð©1ÒþC9€îªÄQÌ3W_Uç{ÓäLdø¢4:‰°'tÆíX#Ïï¤ÛyhÖ»NFàób%÷Ôƒ ~`,oÑñ²@µÕÍ¾„&ùx±Bp)Ð¦ýÚaëü}ºò‡èzwÞGKõÊ¡;6Ãk7iØß",
    k53: "#®3Ç>^ÀÅÆR<9”‚(’]dSÒ|Îëtñ_ì}òø¦{GšŒ€ÍZ“ËcJ6VæYµ+èClåDa?„ÿ‡fvX—‘ÉçgÑô;Ã4-ÊOŠêâÛ qiBýPäoÏ&pàmWsb!wn³ßKí)´¨×Ó§7¸Þ¹5ú¤¡0jé*†Ø@–MöþQ1•ÂáE…hkœ~‰%²ªyHI™¼Ô¿ó±îº«Öï/:2¶£Ð8ue©FA»ƒãŸÜ',õ¥ÕL.ÚTÁ$¯üz·¬xùÙ¾÷NU¢[ð°`r=½ÄÌÈÝû",
    k54: "c|¾gHP£#<Š¢uÞF47¤C„ÖÐ”¥òDÇNYûk†ïŸÑÔóË€°®n:©²ë÷‚{îù-ñj/—'Øt¹xlB@1LÍàì[™§×vð³¨$¶í+Uúçm“Ss±5MOêÚºG!ÃÈÌýåé>¿%8døoÒÂõ(ÏR&iÁ‡6)aÉT«fQ]…Ä*Je¸.¡r^hü‘~ä}_¯A¬¦•XŒÓKZãpßb0ôz3öáƒ=;š9’ÛÝ2½Ê`ÿyÅæÆÕ–qÎâIÙ w,Üœ´‰è»¼EªµÀ?·VWþ",
    k55: "ÎL`8¸Ë^¯ÓN–SÅW·©Ãö‚Ñ³ŠõGÊ¡mK5=à£äšRÁ.Ò´{¼½ukEdú($ØçåÍ™Ïeã~—b†s‡÷»#²nÝDZýÇ¢Â¾Ô0q)Û_«BÚMë®,tÙ1joIþUñº6§€r>î4œ3ÀpŸÐÆ‰¨}J;ø±¹ï“Ìª<¬ @[…/¤Äc¿]YiáP2|X”Tû?wæaAQ:z'%üÿ9C7hÕ„éÖ‘*×¦Þíù’è-µó&Éfâ!VßgòƒÈŒêF•ð+H¶°v¥yôìOÜlx",
    k56: "¿.¹¸$!p`¬Š©°?Ñ»™Ã>·¥¼0;U{QÐìX‰%ä†j¦“¤ƒDßqâøÁÏL¢ÀzZàÅ]|’klëbcØŸéÝ´nù5îrAûFôÛ«d”Ó}Ì:+œSWa8#½vŒiªÂmú×6ïOYJVó9ÿÙÈTò„ÉCæõgêE‚u/(Í•–ç÷ ÄÎão'_¶NíÖ,KèÔ²~1±‡esÊy®ýxÆáR¡-2…&Ò4§Çö€üBPHGþ)*=fÕÚ¯ËÜ£I3Þ[^t³@h‘å¾ºñ<¨—wšµMð7",
    k57: "w8Jøôº†Ýê4îGÔ´*:í!×?ë9üP¿¨^a0æòäAY}ilNTà¬¶¤o©Ç…Ì’hHOKj•uñØÖé½ðf+)‚‰6-‡qFÆÙ€XtúÄÚÍ&'@#%¦ÐÞÓ¯ÿ¡.¾°xÃ”šk±S·v£þn«ÛöÕMs»È“ìƒy¸V<>ýRÂIïÎ`Ñ²[ûWCèp5(1;LóDB—=$r2ÅeÊ]m¥c™UgÏ¢bZ‘É÷~ ,„ÁEõµŸßçŠ–¼ŒÜ/â³zÀ7{®|3ª§œËåá¹QÒãd_ù",
    k58: "áÛóF§¬‚èY*ÈüpeU£ä”Ú.+Ý%^û¹¤ÁñÇðJdâiA±OãNÖÅÄBL_ôoqæIµ¼ë9„S|²#>´ØÎŠWt þË¯}ÍCå4—¨Êy2M`ŒlÉ0Q3õ7ÓD/œ·bföÞkÏý–™Ð°[ªXz:¾Rh&©î@,n¦º]¡ì(Ã¸v~Ü'€ÔÕà6¥Ò‘ÿ†êŸø®‰!ï“½P{gr³ú<¢Gs…=Ñ?À»cé$ExHwÂòÙ¶÷uß‡jƒçVKZm-ù)T;í’Ì5¿a•1×8«Æš",
    k59: "œvùÒËµ2Ÿ‚K¹ë-â&–ç´T^ÔÈ°<OÊ§¶ÿÕIP:†Š¡?þû|³¯D;*i¤C3.¦`6'R¬¿o1ÑWt5«ÚÙü[]¸Ä(ÀÍ“q#ÜE>+zåõa9{ïéÌy@k÷ý€óØ•Lär±¥×s}»Él_ß,Hp)ªñ©¼ƒ‰BÓægÃ·Xcn¨jðUJxGFQ½ø„w/ ZŒÛã—Å%îúVèÁ0f478ômYòÇ!ÂÆ¾ÎNº£”~…àš‘hÝÖ$‡ÏSá®²™MAdu=’ìbíÞö¢eÐê",
    k60: "àç¿k,øô¬B’83Qd¤J‰Sú=òÒ‚ºY®c<óPLÙƒ.ü>7—ÝŠûÆõ«I§C°€5Kz_q¨£‘¼‡Ëñ)aE¾Éæ'Ÿ~åy¯†*èiÛ¡lv´90(íÓ»ÌÁðX™þØ;|ù…ÈrÑµ`x¢t½Ê–HªgáÃÅ@?ÿ¥}$Ä“÷Zh„ÞÏ]ë^îœVš{24·uÍ¹eÐã:bFÇpjý+±ä¸nGmÎ-6OU¶³1ê&ìWÚRÖ¦%² s•TfßMN!AoâÜDöÕ”[éÀ©/#wÔŒ×ïÂ",
    k61: "cµ=Ü¿y¼œ²bwªu†…•¤(i#ÏÄnCû{/p}„vÞ*KþBúÛÑ%îÒWZqÎÖ¯GU½z@¢Ð9ìëÝÆ¹_rŠQåá6`Í¾Éêmí2æ+V0ý)X£]lØ±.ÓÇeòL´aE¡¥ƒ3^ó“è<>o°¨¬FN~¸A dÈ®fðs'ô[«ÁhöãüÚRtÅ,$P?!ù–J|;÷g·ø§ÌTšÔÊ‘ïà&™IŒjâM’ÿ‡Y¶kSŸäçË»ñ7õÀ‰‚:©¦Ã5ÕÙºÂßO1€-³—Hé”84D×x",
    k62: "®.œ¬´Ïp¸ŒàÀ×—$Áæ‘ø©È| ¾¤ŸU[Påî’1xIsº¨ÚËÎÃ¥èqÑ½ù/Ø#D&Í>^Ô£Õ,¿GÓÊ¶ònj:vWìªZ4¢6!Ðñð»tû±{e«éâ`íþ“ý…µC@Òu·ÝAÅš5fÄEõ²Mäã÷bÇ-–†Ü„~=ƒlVdß_X%aNFSêçkmë°;¼Â•ü7JY3R¹‡Š™O‚]9§³Hgÿo+iry?Q)É0¡‰”á<K}Ìóc8ÞÛ€ú'TïÙLÖh(ôöB*Æwz2¦¯",
    k63: "j}?€gÏÜÓ«®m|Q%ñÇ@`^ÊkìV»…œfÖUüe;±_ÐêDÎ—ú¿!Þ¸TX~Ù÷*r/L·p=9©îíèç6é´†Æ¢WØ‰’K“ù¥àÈÅs:â¹Õ¨nŸc'ýËº<Û¯P¡(”a5N•Fá‘z2Œªþ¶Ä[µJyô&¦ÃG0Éd¾8vÒå§l„qEöiO>-ï{½3 ÀbÿMš–óÌY×#7ðBÑAo,‡.IRõ)‚Â™°x³Ý1¼H¤ƒëu]ûhwtSãÁÍÔæC4²+ZßÚø¬£òä$Š",
    k64: "5±aj÷¶ÿôõ¨M”Z0¼;¿_<½ÙQÞé€xÕÑh¬¥ógAH‘u}K-'»CíeGN!U{>¡ø¸ŠVå4¦ÛÔ2Œ‰ƒÀ“â?D„¢1É6bšËÄcîµÈdÌìò XÃwïêàrIl(mRÍÇk`7ã,úùS£/ð´pyÅ©…ÐÂ&]|×°ªÁØÏÚÖOöþBq’YÜnJ^F—tèÎ=ûzü[·3•Òäç²+:§–8†œsÓ‚W¹‡æi$PÊ¾º%ß9oýf.ŸE¯«vT¤®³ëL#~ñá@)Ý*Æ™"
    }

}
